<template>
  <v-data-table
    :headers="headers"
    :items="assessmenttools"
    :items-per-page="10"
    item-key="id"
    show-expand
    class="elevation-1"
  >
    <template v-slot:expanded-item="{ headers, item }">
      <v-container class="d-flex align-center">
        <v-simple-table dense>
          <template v-slot:default>
            <thead class="text-center">
              <td>From</td>
              <td>End</td>
              <td>Level</td>
            </thead>
            <tbody>
              <tr
                v-for="toollevel in item.assessment_tool_level"
                :key="toollevel.id"
              >
                <td class="text-right">{{ toollevel.bracket_start }}</td>
                <td class="text-right">{{ toollevel.bracket_end }}</td>
                <td>
                  {{ toollevel.level }}
                </td>
              </tr>
            </tbody>
          </template>
          
        </v-simple-table>
      </v-container>
    </template>
    <template v-slot:no-data>
              <td :colspan="headers.length">
                NO RECORD
              </td>
          </template>
  </v-data-table>
</template>

<script>
import { sync, dispatch } from "vuex-pathify";

export default {
  computed: {
    ...sync("assessmenttools", ["assessmenttools"]),
  },
  data() {
    return {
      expanded: [],
      headers: [
        {
          text: "Assessment Tool",
          align: "start",
          sortable: false,
          value: "title",
        },
        {
          text: "Subject",
          value: "subject",
        },
        { text: "", value: "data-table-expand" },
      ],
    };
  },

  beforeMount() {
    this.loadData();
  },

  methods: {
    async loadData() {
      await dispatch("assessmenttools/loadData");
    },
  },
};
</script>
